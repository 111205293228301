import Customer from '@/model/customer';

interface UserProfileState {
  uuid: string; // not being used?
  email: string;
  availableCustomers: Customer[];
  isAuthenticated: boolean;
  auth0AccessToken: string;
}

const getDefaultState = () => {
  return {
    uuid: '',
    email: '',
    availableCustomers: [],
    isAuthenticated: false,
    auth0AccessToken: ''
  } as UserProfileState;
};


//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
const state = getDefaultState();


//----------------------------------------------------------------------------
// Getters
//----------------------------------------------------------------------------

const getters = {

  getCustomerByUuid: ( state: UserProfileState ) => ( uuid: string ) => {
    return state.availableCustomers.find( customer => customer.uuid === uuid );
  },

  /**
   * @todo Not sure why we need a dedicated getter method for this, since
   * it isn't a computed property.
   */
  getAuth0AccessToken: ( state: UserProfileState ) => {
    return state.auth0AccessToken;
  }
};


//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------

const mutations = {

  /**
   * Reset the state back to the default values.
   */
  resetState( state: UserProfileState ) {
    Object.assign( state, getDefaultState() );
  },

  setIsAuthenticated( state: UserProfileState, isAuthenticated: boolean ) {
    state.isAuthenticated = isAuthenticated;
  },

  setUserProfile( state: UserProfileState, user: any ) {
    state.email = user.email;
  },

  setAvailableCustomers( state: UserProfileState, customers: Customer[] ) {
    state.availableCustomers = customers;
  },

  setAuth0AccessToken( state: UserProfileState, accessToken: string ) {
    state.auth0AccessToken = accessToken;
  }
};


//----------------------------------------------------------------------------
// Actions
//----------------------------------------------------------------------------

const actions = {

};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};