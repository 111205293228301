
import Vue from 'vue';
import Component from 'vue-class-component';
import { CreateSalesOrder } from '@/api/CreateSalesOrder';
import { ApiStatusCodes } from '@/commons/ApiStatusCodes';

@Component
export default class SalesOrderSubmission extends Vue {

  //----------------------------------------------------------------------------
  // Local Variables
  //----------------------------------------------------------------------------

  isSendingRequest: boolean = true;
  isOrderCreated: boolean = false;
  hasError: boolean = false;

  
  //----------------------------------------------------------------------------
  // Computed Properties
  //----------------------------------------------------------------------------



  //----------------------------------------------------------------------------
  // Lifecycle Events
  //----------------------------------------------------------------------------

  async mounted() {
    await this.createSalesOrder();
  }


  //----------------------------------------------------------------------------
  // Methods
  //----------------------------------------------------------------------------

  startNewOrder() {
    this.$emit( 'restart-order' );
  }

  editOrder() {
    this.$emit( 'edit-order' );
  }

  async createSalesOrder() {

    let isOrderCreated: boolean = false,
        hasError: boolean = false;

    this.isSendingRequest = true;

    // Get the order from the state store.
    const order = this.$store.state.salesOrder,
          products = [ ...order.productCart.values() ];

    let orderRequest = {
      userEmail: this.$store.state.user.email,
      customerUuid: order.customerUuid,
      requestedDeliveryDate: order.requestedDeliveryDate,
      purchaseOrderNumber: order.purchaseOrderNumber,
      lineItems: new Array()
    };

    for ( const product of products ) {

      let lineItem = {
        itemNumber: product.itemNumber,
        quantity: product.quantity,
        unitPrice: product.unitPrice,
        discountPercent: product.discountPercent
      };

      orderRequest.lineItems.push( lineItem );
    }


    // Execute the API request.
    let response = await CreateSalesOrder( orderRequest );
  
    // Handle the response.
    if ( response?.data ) {

      if ( response.data.code === ApiStatusCodes.Created ) {

        isOrderCreated = true;
        this.$store.commit( 'salesOrder/reset' );
      }
      else {
        hasError = true;

        // Add logic to handle different status code types if needed.
      }
    }
    else {
      hasError = true;
    }

    this.isOrderCreated = isOrderCreated;
    this.hasError = hasError;
    this.isSendingRequest = false;
  }
}
