export default class Product {
  itemNumber: string = '';
  displayName: string = '';
  unitPrice: number = 0.00;
  discountPercent: number;
  isAvailable: boolean;
  quantity: number = 0;
  baseUnitOfMeasureCode: string = '';
  growOpsDescription: string = '';
  growOpsInternalUnitCount: number = 0;
  growOpsInternalUnitWeight: number = 0;
  growOpsInternalUnitUom: string = '';

  constructor( data: Partial<Product> ) {
    Object.assign( this, data );
  }

  get netAmount(): number {
    return ( this.quantity > 0 )
      ? ( this.quantity * this.unitPrice ) - this.discountAmount
      : 0.00;
  }

  get unitDiscountAmount(): number {
    return ( this.discountPercent )
      ? this.unitPrice * ( this.discountPercent / 100 )
      : 0;
  }

  get discountAmount(): number {
    return this.quantity * this.unitDiscountAmount;
  }
}