import User from "@/model/app-user";
import axios from "axios";

/**
 * Sets axios http request and response interceptors
 */
export function setHttpInterceptors() {
  axios.interceptors.response.use(
    response => {
      // Catch and throw GraphQL errors since they return code 200, which looks like a "success".
      if (response.data.errors) {
        return Promise.reject(
          new Error(
            response.data.errors
              .map((e: any) => {
                return e.message;
              })
              .join(", ")
          )
        );
      }
      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );
}

/**
 * Sets axios interceptors, updates user profile.
 * @param {Object} store - Vuex store reference
 */
export async function initializeAfterSignIn(store: any, user: User) {
  await setHttpInterceptors();

  // This will signal to components that authentication is complete and they can
  // begin normal operation (including requesting data using the token).
  store.commit( 'user/setIsAuthenticated', true );
  store.commit( 'user/setUserProfile', user );
}
