/* eslint-disable no-console */
import axios, { AxiosResponse } from "axios";
import store from "../store";

export function sendQuery(query: string = "") {
  if (!query) return;
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
        query: query
      })
      .then(
        (success: any) => {
          resolve(success);
        },
        (failure: any) => {
          reject(failure);
        }
      );
  });
}

export async function sendApiRequest(uri: string = "", payload: any = {}): Promise<AxiosResponse<any>> {
  
  let response = await axios
    .post(
      process.env.VUE_APP_API_URI ? process.env.VUE_APP_API_URI + uri : "",
      payload,
      {
        headers: {
          Authorization: "Bearer " + store.getters["user/getAuth0AccessToken"]
        }
      }
    ).catch((error)=> error);
  if (response.status >= 400) {
    return response;
  }
  if (typeof response.data === 'string' && response.data.length > 0) {
    response.data = JSON.parse(response.data);
  }
  return response;
}
