
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { DateTime } from 'luxon';
import { GetProductsByCustomer } from '@/api/GetProducts';
import { required } from 'vuelidate/lib/validators';

@Component({
  mixins: [ validationMixin ],
  validations: {
    purchaseOrderNumber: {
      required
    }
  }
})
export default class SalesOrderForm extends Vue {

  //----------------------------------------------------------------------------
  // Local Variables
  //----------------------------------------------------------------------------
  isLoadingProducts: boolean = false;

  //----------------------------------------------------------------------------
  // Computed Properties
  //----------------------------------------------------------------------------

  /**
   * Retrieve available customers from the user profile store.
   */
  get availableCustomers() {
    return this.$store.state.user.availableCustomers;
  } 

  get selectedCustomerUuid() {
    return this.$store.state.salesOrder.customerUuid || this.availableCustomers[0]?.uuid || '';
  }

  get selectedCustomer() {

    let selectedCustomer = null,
        availableCustomers: any[] = this.availableCustomers,
        activeCustomerUuid = this.$store.state.salesOrder.customerUuid || '';

    if ( this.availableCustomers.length ) {
      if ( activeCustomerUuid ) {
        selectedCustomer = availableCustomers.find( ( customer: any ) => customer.uuid === activeCustomerUuid );
      }
    
      else {
        selectedCustomer = availableCustomers[0];
      }
    }

    return selectedCustomer;
  }

  get requestedDeliveryDate() {
    return this.$store.state.salesOrder.requestedDeliveryDate || this.minimumDeliveryDate;
  }

  set requestedDeliveryDate( requestedDate: string ) {
    let requestedDeliveryDate = DateTime.fromISO( requestedDate ).toISODate();
    this.$store.commit( 'salesOrder/setRequestedDeliveryDate', requestedDeliveryDate );
  }

  get allowedDeliveryDays(): number[] {
    return this.selectedCustomer.allowedDeliveryDays;
  }

  /**
   * Retrieve the minimum valid requested delivery date option.
   */
  get minimumDeliveryDate(): string {    
    // Minimum order date is based on order lead time, cut-off time, and allowed delivery days.
    // Lead time and cut-off time currently hard coded for 3 days and 5pm EST.
    let deliveryDate = DateTime.utc(),
        allowedDeliveryDays = this.allowedDeliveryDays,
        leadDays = 3,
        cutOffHour = 22;

    // Add an additional lead day if we are past the cut-off time.
    // Likely need to refactor if we change to dynamic cut-off times?
    if ( deliveryDate.hour >= cutOffHour ) {
      leadDays++;
    }

    // Modify minimum date with order lead days.
    deliveryDate = deliveryDate.plus( { days: leadDays } );

    // Check if the minimum lead date is on a valid delivery day.
    if ( allowedDeliveryDays.length && ! this.isValidDeliveryDay( deliveryDate.toISODate() ) ) {

      // The date we have isn't a valid delivery day for the customer, so we need to pick the next closest one.
      // Determine how many days need to be added to reach the next valid delivery day.
      // If next day isn't found, use the first weekday option + 7 to account for the next week.
      // Handle Luxon weekday formatting to adjust down to 0-6 starting Sunday.
      let deliveryWeekdayNumber = deliveryDate.weekday % 7,
          nextDeliveryDay = allowedDeliveryDays.find( ( weekday ) => weekday > deliveryWeekdayNumber ) || ( allowedDeliveryDays[0] + 7 ),
          dayOffset = nextDeliveryDay - deliveryWeekdayNumber;

      deliveryDate = deliveryDate.plus( { day: dayOffset } );
    }

    return deliveryDate.toISODate();
  }

  get purchaseOrderNumber() {
    return this.$store.state.salesOrder.purchaseOrderNumber || '';
  }
 
  set purchaseOrderNumber( purchaseOrderNumber: string ) {
    this.$store.commit( 'salesOrder/setPurchaseOrderNumber', purchaseOrderNumber );
  }

  /**
   * Try to validate and retrieve errors for the purchase order number field.
   */
  get purchaseOrderNumberErrors() {
    let errors: Array<string> = [];

    if ( this.$v.purchaseOrderNumber.$dirty ) {

      if ( ! this.$v.purchaseOrderNumber.required ) {
        errors.push( 'Purchase order number is required.' );
      }

    }

    return errors;
  }


  //----------------------------------------------------------------------------
  // Lifecycle Events
  //----------------------------------------------------------------------------

  async created() {
    await this.init();
  }

  async mounted() {
    
  }


  //----------------------------------------------------------------------------
  // Methods
  //----------------------------------------------------------------------------

  /**
   * Set initial data.
   */
  private async init() {
    
    if ( ! this.$store.state.salesOrder.customerUuid ) {
      this.$store.commit( 'salesOrder/setCustomerUuid', this.selectedCustomerUuid );
    }

    if ( ! this.$store.state.salesOrder.requestedDeliveryDate.length ) {
      this.$store.commit( 'salesOrder/setRequestedDeliveryDate', this.requestedDeliveryDate );
    }

    if ( ! this.$store.state.salesOrder.availableProducts.length ) {
      await this.updateAvailableProducts();
    }
  }

  /**
   * Update related fields when the customer has been updated.
   */
  async updateSelectedCustomer( uuid: string ) {
    
    // Trigger the sales order action to reset portions of the store. 
    await this.$store.dispatch( 'salesOrder/updateSelectedCustomer', uuid );

    // Reset the delivery date if it is not valid for the new customer.
    if ( ! this.isValidDeliveryDay( this.requestedDeliveryDate ) ) {
      this.$store.commit( 'salesOrder/setRequestedDeliveryDate', '' );
      this.requestedDeliveryDate = this.minimumDeliveryDate;
    }

    // Update the available products.
    await this.updateAvailableProducts();
  }
  
  /**
   * Validate the order form before proceeding.
   */
  reviewOrder() {

    let isValid = true;

    // Check vuelidate rules.
    this.$v.$touch();
    isValid = ! this.$v.$invalid;

    // Do we have items in our cart?
    if ( ! this.$store.state.salesOrder.productCart.size ) {
      isValid = false;
    }

    if ( ! this.isValidDeliveryDay( this.requestedDeliveryDate ) ) {
      isValid = false;
    }

    if ( isValid ) {
      this.$emit( 'order-submitted' );
    }
  }

  /**
   * Validation method utilized by the date-picker component.
   */
  isValidDeliveryDay( calendarDate: string ) {
    let date = DateTime.fromFormat( calendarDate, 'yyyy-MM-dd' );
    return this.allowedDeliveryDays.includes( date.weekday % 7 );
  }

  /**
   * Retrieve and update the available products in the sales order store.
   */
  async updateAvailableProducts() {

    this.isLoadingProducts = true;

    let availableProducts = await GetProductsByCustomer({
      uuid: this.selectedCustomerUuid,
      dynamicsUuid: '',
      orderDate: DateTime.now().toISODate()
    });

    this.$store.commit( 'salesOrder/setAvailableProducts', availableProducts );

    this.isLoadingProducts = false;
  }
}
