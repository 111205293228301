
import { getImageSrcFromBase64 } from "@/util/helpers";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ProductImage extends Vue {
  @Prop({ default: "" })
  src!: string;

  @Prop({ default: "50px" })
  height!: string;

  @Prop({ default: "50px" })
  width!: string;

  @Prop({ default: false })
  base64!: boolean;

  @Prop()
  onClick!: any;

  get getImageBase64(): string {
    if (!this.src) return "";

    return getImageSrcFromBase64(this.src);
  }
}
