
import { getImageSrcFromBase64 } from "@/util/helpers";
import { Component, Vue, Prop } from "vue-property-decorator";

export interface CarouselItem {
  title: string;
  id: string;
  src: string;
}

@Component
export default class ImageCarousel extends Vue {
  @Prop({ default: [] })
  images!: Array<CarouselItem>;

  @Prop({ default: false })
  base64!: boolean;

  getImageBase64(base64: string): string {
    if (!base64) return "";

    return getImageSrcFromBase64(base64);
  }
}
