import { Route } from "vue-router";
import { getInstance } from "../plugins/auth";

export const authGuard = (to: Route, _from: Route, next: any) => {
  const authService: any = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    if (window.location.search.includes("error=")) {
      throw new Error("Access denied");
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check the auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before checking isAuthenticated
  authService.$watch("loading", (loading: boolean) => {
    if (loading === false) {
      return fn();
    }
  });
};
