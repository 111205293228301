import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#772583",
        secondary: "#97d700",
        accent: "#62a500",
        error: "#f44336",
        warning: "#ffc107",
        info: "#03a9f4",
        success: "#62a500"
      }
    }
  }
});
