import Vue from "vue";
import Vuex from "vuex";

// imports all modules listed in modules/index
import modules from "./modules";

Vue.use(Vuex);

const store = new Vuex.Store({
  actions: {
    resetAllStores: function(context: any) {
      context.commit("user/resetUserStore");
      context.commit("sales/resetSalesStore");
    }
  },
  modules
});

export default store;
