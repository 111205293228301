
import { Vue, Component, Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import Product from '@/model/product';
import { formatCurrency } from '@/util/helpers';
import { DateTime } from 'luxon';

@Component({
  mixins: [ validationMixin ],
  validations: {

  }
})
export default class SalesOrderProductsTable extends Vue {

  //----------------------------------------------------------------------------
  // Properties
  //----------------------------------------------------------------------------

  @Prop({ default: false })
  isLoading!: boolean;


  //----------------------------------------------------------------------------
  // Local Variables
  //----------------------------------------------------------------------------

  productHeaders = [
    { text: 'Item #', value: 'itemNumber' },
    { text: 'Description', value: 'displayName' },
    { text: 'Quantity', value: 'quantity' },
    { text: 'Unit Price', value: 'unitPrice' },
    { text: 'Discount %', value: 'discountPercent'},
    { text: 'Subtotal', value: 'netAmount' }
  ];

  showUnavailableProducts: boolean = false;

  //----------------------------------------------------------------------------
  // Computed Properties
  //----------------------------------------------------------------------------
  
  get availableProducts(): Product[] {
    return this.$store.getters['salesOrder/getAvailableProducts'].filter(
      ( product: Product ) => ( product.isAvailable || this.showUnavailableProducts )
    );
  }

  get showRebrandedItemAlert(): boolean {

    const startDate = DateTime.fromISO( '2023-06-07' ),
          currentDate = DateTime.now(),
          isValidDate: boolean = ( startDate.startOf( 'day' ) <= currentDate && currentDate <= startDate.endOf( 'month' ) ),
          hasRebrandedItem: boolean = this.availableProducts.some( product => product.itemNumber === "10379" );

    return hasRebrandedItem && isValidDate;
  }

  get rebrandedItemNote(): string {
    
    const currentDate = DateTime.now(),
          startDate = DateTime.fromISO( '2023-06-13' );

    let note = 'SPRING BREAK &mdash; renamed FRESH SQUEEZE beginning June 14th';

    if ( startDate.startOf( 'day' ) <= currentDate ) {
      note = 'FRESH SQUEEZE &mdash; formerly SPRING BREAK';
    }

    return note;
  }
 
  //----------------------------------------------------------------------------
  // Lifecycle Events
  //----------------------------------------------------------------------------

  mounted() {

  }


  //----------------------------------------------------------------------------
  // Methods
  //----------------------------------------------------------------------------

  /**
   * Add or remove products in the cart when quantity changes.
   */
  updateProductCart( product: Product ) {

    // Check quantity first, as that will dictate our actions.
    if ( product.quantity > 0 ) {
      this.$store.commit( 'salesOrder/updateCartItem', product );
    }
    else {
      this.$store.commit( 'salesOrder/removeCartItem', product.itemNumber );
    }
  }

  toggleShowUnavailable() {
    this.showUnavailableProducts = ! this.showUnavailableProducts;
  }

  formatCurrency( amount: number ) {
    return formatCurrency( amount );
  }
}
