import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueRouter, { RawLocation } from "vue-router";
import vuetify from "./plugins/vuetify";
import "@/components/"; // lazy-load components
import { Auth0Plugin } from "./plugins/auth";
import LogRocket from "logrocket";

// Initialize logrocket
if (process.env.VUE_APP_ENV === "prod") {
  LogRocket.init("nehdcs/sales-order-portal");
}

Vue.use(Auth0Plugin, {
  onRedirectCallback: (appState: { targetUrl: RawLocation }) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(VueRouter);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  // @ts-ignore
  vuetify,
  render: h => h(App)
}).$mount("#app");
