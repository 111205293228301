
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { ContactSales } from "@/api/ContactSales";

@Component
export default class ContactSalesperson extends Vue {
  customerMessage: string = "";
  alert: boolean = false;

  get salesperson() {
    return this.$store.getters["user/getSalespersonName"];
  }

  get quote() {
    return this.$store.getters["sales/getEntireQuote"];
  }

  get messageObject() {
    let entireQuote = this.quote;

    return {
      customer_uuid: entireQuote.customer_uuid,
      customer_message: this.customerMessage
    };
  }

  closeDialog() {
    this.alert = false;
    this.customerMessage = "";
  }

  sendMessage() {
    ContactSales(this.messageObject).then(
      (success: any) => {
        this.alert = true;
      },
      (fail: any) => {
        //console.log(fail);
      }
    );
  }
}
