export const ICON_DEFINITIONS: { [key: string]: any } = {
  base: {
    close: "mdi-close",
    cancel: "mdi-cancel",
    delete: "mdi-delete",
    archive: "mdi-package-down",
    complete: "mdi-check-circle",
    inProgress: "mdi-progress-check",
    add: "mdi-plus",
    edit: "mdi-square-edit-outline",
    copy: "mdi-content-duplicate",
    error: "mdi-alert",
    alert: "mdi-alert",
    camera: "mdi-camera-alt",
    notes: "mdi-comment-text-outline",
    print: "mdi-printer",
    barcode: "mdi-barcode",
    quantity: "mdi-pound-box",
    yield: "mdi-scale-balance",
    date: "mdi-calendar",
    time: "mdi-clock-outline",
    datetime: "mdi-calendar-clock",
    goto: "mdi-chevron-right",
    goback: "mdi-chevron-left",
    left: "mdi-arrow-left",
    ok: "mdi-check-circle",
    cut: "mdi-content-cut",
    pie: "mdi-chart-pie",
    menu: "mdi-menu",
    plant: "mdi-leaf",
    info: "mdi-information-outline",
    swap: "mdi-swap-horizontal",
    selected: "mdi-checkbox-marked",
    selectAll: "mdi-checkbox-blank-outline",
    selectPartial: "mdi-minus-box",
    deselect: "mdi-close-box",
    details: "mdi-format-list-bulleted",
    overview: "mdi-eye",
    thumbsUp: "mdi-thumb-up",
    expandNavigation: "mdi-chevron-right",
    filter: "mdi-filter",
    name: "mdi-label",
    reference: "mdi-magnify",
    save: "mdi-content-save-outline",
    currency: "mdi-currency-usd",
    reset: "mdi-refresh",
    reload: "mdi-refresh",
    tableFile: "mdi-file-table-outline",
    export: "mdi-file-export"
  },
  manufacturing: {
    default: "mdi-factory"
  },
  iterator: {
    cardView: "mdi-checkbox-multiple-blank-outline",
    tableView: "mdi-table",
    ganttView: "mdi-chart-timeline"
  },
  facility: {
    campus: "mdi-office-building",
    zone: "mdi-door",
    plot: "mdi-grid",
    plotType: "mdi-tag-text-outline",
    zoneMap: "mdi-floor-plan",
    PO: "mdi-cart",
    vendor: "mdi-store"
  },
  device: {
    temphud: "mdi-thermometer",
    RelayUnit: "mdi-power-plug",
    Camera: "mdi-camera",
    water: "mdi-beaker",
    CO2: "mdi-periodic-table-co2",
    vwc: "mdi-gradient",
    OdroidC2: "mdi-raspberry-pi",
    ip: "mdi-lan",
    tag: "mdi-tag",
    activity: "mdi-history",
    offline: "mdi-close-network",
    online: "mdi-access-point-network",
    location: "mdi-office-building",
    room: "mdi-door",
    rack: "mdi-grid",
    usb1: "mdi-usb",
    usb2: "mdi-usb",
    usb3: "mdi-usb",
    usb4: "mdi-usb",
    ledOn: "mdi-led-on",
    ledOff: "mdi-led-off",
    locked: "mdi-lock",
    unlocked: "mdi-lock-open",
    toggleOn: "mdi-toggle-switch",
    toggleOff: "mdi-toggle-switch-off",
    default: "mdi-developer-board"
  },
  label: {
    default: "mdi-label"
  },
  inventoryTransfer: {
    transfer: "mdi-bank-transfer",
    destination: "mdi-bank-transfer-in"
  },
  schedule: {
    details: "mdi-timetable",
    type: "mdi-calendar-clock",
    minutes: "mdi-progress-clock",
    alternating: "mdi-repeat",
    weekday: "mdi-calendar-range",
    dayOfMonth: "mdi-calendar-multiple",
    time: "mdi-clock-outline",
    duration: "mdi-timer-sand",
    cascade: "mdi-sync",
    power: "mdi-power",
    link: "mdi-calendar-plus",
    removeLink: "mdi-link-off",
    edit: "mdi-square-edit-outline",
    delete: "mdi-delete",
    device: "mdi-developer-board",
    default: "mdi-calendar-clock"
  },
  order: {
    propagation: "mdi-chart-timeline",
    stage: "mdi-tag",
    wip: "mdi-leaf",
    fullHarvest: "mdi-barley",
    germinationTimeline: "mdi-shovel",
    propagationTimeline: "mdi-call-split",
    productionTimeline: "mdi-factory",
    relocationTimeline: "mdi-cube-send",
    harvestTimeline: "mdi-package-variant",
    cancelTimeline: "mdi-cancel",
    writeoffTimeline: "mdi-delete-empty",
    researchTimeline: "mdi-flask-outline",
    creationTimeline: "mdi-creation",
    date: "mdi-calendar-range",
    varieties: "mdi-sitemap",
    lotId: "mdi-barcode",
    default: "mdi-leaf",
    harvestReady: "mdi-calendar-check",
    health: "mdi-heart-pulse",
    visibleHealth: "mdi-heart-circle-outline",
    ailments: "mdi-heart-broken",
    growth: "mdi-trending-up",
    measurements: "mdi-ruler",
    lighting: "mdi-ceiling-light",
    distance: "mdi-map-marker-distance",
    humidity: "mdi-weather-fog",
    electric: "mdi-battery-charging",
    hole: "mdi-music-note-whole"
  },
  orderStage: {
    propagation: "mdi-shovel",
    production: "mdi-factory",
    harvest: "mdi-package-variant",
    regrowth: "mdi-barley",
    creation: "mdi-alert",
    complete: "mdi-check-circle",
    cancelled: "mdi-cancel"
  },
  packout: {
    default: "mdi-package-variant",
    salesReport: "mdi-clipboard",
    shortedSalesReport: "mdi-clipboard-alert",
    store: "mdi-store",
    plant: "mdi-leaf"
  },
  plant: {
    seeds: "mdi-blackberry",
    media: "mdi-gradient",
    produce: "mdi-fruit-cherries"
  },
  profile: {
    user: "mdi-account",
    signout: "mdi-logout-variant"
  },
  purchasing: {
    default: "mdi-credit-card",
    vendor: "mdi-store",
    waiting: "mdi-timer-sand",
    confirmed: "mdi-timer-sand-full"
  },
  purchaseOrder: {
    received: "mdi-package-variant-closed"
  },
  report: {
    default: "mdi-chart-line",
    invoices: "mdi-receipt"
  },
  sales: {
    salesOrders: "mdi-file-multiple",
    customers: "mdi-account-box-multiple",
    default: "mdi-finance",
    cash: "mdi-cash"
  },
  delivery: {
    customer: "mdi-map-marker",
    boxCount: "mdi-pound-box",
    delivery: "mdi-truck",
    pickup: "mdi-package-up",
    boxType: "mdi-package-variant",
    origin: "mdi-home-map-marker",
    po: "mdi-format-list-checkbox",
    packed: "mdi-package-variant-closed",
    pack: "mdi-package-down",
    unpack: "mdi-package-up",
    load: "mdi-truck-delivery",
    loaded: "mdi-truck-check",
    accept: "mdi-clipboard-check-outline",
    rejection: "mdi-keyboard-return",
    return: "mdi-undo",
    credit: "mdi-currency-usd-off",
    temperature: "mdi-thermometer",
    default: "mdi-truck",
    tpl: "mdi-office-building"
  },
  inventory: {
    bom: "mdi-receipt",
    crop: "mdi-corn",
    recipe: "mdi-receipt",
    category: "mdi-rhombus-split",
    categoryParent: "mdi-sitemap",
    items: "mdi-cube-outline",
    itemNumber: "mdi-pound-box",
    itemType: "mdi-filter",
    uom: "mdi-ruler",
    default: "mdi-forklift",
    produce: "mdi-export",
    consume: "mdi-import",
    tag: "mdi-tag",
    cyclecount: "mdi-clipboard-flow",
    rejection: "mdi-keyboard-return",
    lot: "mdi-select-group"
  },
  dashboard: {
    default: "mdi-monitor-dashboard"
  },
  user: {
    email: "mdi-email",
    phone: "mdi-phone"
  },
  admin: {
    users: "mdi-account-supervisor",
    config: "mdi-settings",
    default: "mdi-account-supervisor-circle"
  },
  default: "mdi-hexagon-outline"
};

export function getIcon(category: string = "", icon: string = "") {
  // Splits the first param into two parts if it includes `.`
  // i.e. `user.email`
  if (category && category.includes(".") && !icon) {
    let temp: any = category.split(".");
    category = temp[0] || "";
    icon = temp[1] || "";
  }
  if (category && ICON_DEFINITIONS[category]) {
    if (icon && ICON_DEFINITIONS[category][icon]) {
      return ICON_DEFINITIONS[category][icon];
    } else if (ICON_DEFINITIONS[category].default) {
      return ICON_DEFINITIONS[category].default;
    }
  }
  return ICON_DEFINITIONS.default;
}

export function iconExists(category: string = "", icon: string = "") {
  if (category && ICON_DEFINITIONS[category]) {
    if (icon && ICON_DEFINITIONS[category][icon]) {
      return true;
    }
  }
  return false;
}
