
import { Vue, Component } from "vue-property-decorator";
// import { clearTimeout } from "timers";

@Component
export default class DataRefresh extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Local Variables - Replaces data()
  dataRefresh: any = {
    halt: false,
    timeout: {},
    interval: 30000
  };
  showPreloader: boolean = true;

  // LIFECYCLE EVENTS ---------------------------------------------
  beforeDestroy() {
    clearTimeout(this.dataRefresh.timeout);
    this.dataRefresh.halt = true;
  }

  // METHODS ------------------------------------------------------
  /**
   * Sets a simple refresh, given a method and any params
   */
  scheduleRefresh(method: any, params: any = null) {
    if (method) {
      method(params);
      this.setRefreshTimeout(this.scheduleRefresh, method, params);
    }
  }

  /**
   * Sets an async refresh, given a method and any params
   * Will set showPreloader to false on success
   */
  scheduleAsyncRefresh(method: any, params: any = null) {
    if (method) {
      method(params).then(
        (success: any) => {
          if (typeof this.showPreloader !== "undefined") {
            this.showPreloader = false;
          }
          if (!this.dataRefresh.halt)
            this.setRefreshTimeout(this.scheduleAsyncRefresh, method, params);
        },
        (fail: any) => {
          if (!this.dataRefresh.halt)
            this.setRefreshTimeout(this.scheduleAsyncRefresh, method, params);
        }
      );
    }
  }

  /**
   * Mutates dataRefresh.interval
   */
  setRefreshInterval(interval: number = 30000) {
    this.dataRefresh.interval = interval;
  }

  /**
   * Sets the timeout object that triggers the refresh
   * @param schedule_method Set simple schedule or async
   * @param original_method Method that's called in the schedule refresh
   * @param params Params passed to original_method
   */
  setRefreshTimeout(
    schedule_method: any,
    original_method: any,
    params: any = null
  ) {
    if (schedule_method && original_method) {
      this.dataRefresh.timeout = setTimeout(function() {
        schedule_method(original_method, params);
      }, this.dataRefresh.interval);
    }
  }
}
