import { GetAppSettingsByAppName } from "@/api/GetAppSettings";


interface AppState {
  appSettings: any,
  appName: string
}

const getDefaultState = () => {
  return {
    appSettings: {
      maintenance_mode: false,
      maintenance_message: ""
    },
    appName: "SalesPortal"
  } as AppState
}

const state = getDefaultState();

// Getters

const getters = {
  getAppSettings: (state: AppState ) => {
    return state.appSettings;
  },
  getMaintenanceMode: (state: AppState ) => {
    return state.appSettings.maintenance_mode
  },
  getMaintenanceMessage: (state: AppState) => {
    return state.appSettings.maintenance_message
  }
}

// mutations
const mutations = {
  setAppSettings(state: AppState, appSettings: any) {
    state.appSettings = appSettings
  }
}

// actions
const actions = {
  retrieveAppSettings: async function (context: any) {
    const appSettings = await GetAppSettingsByAppName(context.state.appName)
    context.commit("setAppSettings", appSettings)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
