
import { Component, Prop, Mixins } from "vue-property-decorator";
import { required, requiredIf } from "vuelidate/lib/validators";
import { getIcon } from "@/util/icons";
import { formatTimestamp, getUTCFromDate, getUTCNow } from "@/util/helpers";
import Form from "@/mixins/Form.vue";
import moment from "moment";

@Component({
  validations: {
    date: { required: requiredIf("required") }
  }
})
export default class BaseDatePicker extends Mixins(Form) {
  // PROPS ---------------------------------------------------------------------
  @Prop({ default: "" })
  min!: string;

  @Prop({ default: "" })
  max!: string;

  @Prop({ default: "" })
  label!: string;

  @Prop({ default: "" })
  value!: string;

  @Prop({ default: getIcon("base", "date") })
  icon!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  clearable!: boolean;

  @Prop({
    default: () => {
      return true;
    }
  })
  allowedDates!: Function;

  // LOCAL VARIABLES -----------------------------------------------------------
  showMenu: boolean = false;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns formatted date from `this.value`.
   * ie, `YYYY-MM-DD`
   * @returns {string}
   */
  get date() {
    return this.getPickerDate(this.value);
  }

  /**
   * Returns formatted date from `this.value`.
   * ie, `YYYY-MM-DD`
   * @returns {string}
   */
  get minDate() {
    // Determine minimum date customers are allowed to request sales order based on delivery type
    // Delivery and Pickup: 1 day min
    // 3PL: 3 day min
    // ngraham 1/14/22 Updated this to be a 3 day min for everything, and if it's after 5 PM EST we need to bump it 4 days.
    let timeToCheck = moment.utc().format("HH");
    let minDateLead = 3; // Default

    if(+timeToCheck >= 22) {
      minDateLead = 4;
    }

    
    
    let minDate = moment(new Date()).utc();
    return "" + minDate.add(minDateLead, "d").format("YYYY-MM-DD");
  }

  /**
   * Emits input to change `this.value`.
   * ie, `YYYY-MM-DD +Timezone`
   */
  set date(value: string) {
    this.$emit("input", getUTCFromDate(value));
  }

  /**
   * Returns true if any validation errors exist
   * @returns {boolean}
   */
  get hasErrors() {
    return this.$v.$anyError;
  }

  /**
   * Returns a prettified display date.
   * ie, `ddd, MMMM Do YYYY`
   * @returns {string}
   */
  get formattedDate() {
    return formatTimestamp(this.date, "date");
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Returns an ISO date string.
   * ie, `YYYY-MM-DD`
   * @returns {string}
   */
  getPickerDate(value: string) {
    return formatTimestamp(value, "datepicker");
  }
}
