import { sendApiRequest } from "@/api/AxiosWrapper";
import Product from "@/model/product";
import { mapProduct } from "@/util/helpers";

/**
 * @deprecated
 */
export async function GetCustomerProducts(customerId: string): Promise<Array<Product> | Error> {
  const response = await sendApiRequest("/oms/product/getbycustomerid", {
    customerId
  });
  if (response.status === 200) {
    const productsRaw: Array<any> = response.data.customer_products;
    let products: Array<Product> = productsRaw.map<Product>((productRaw: any) => {
      return mapProduct(productRaw);
    });
    return products;
  } else {
    return Error(response.data);
  }
}


interface CustomerProductFilter {
  uuid: string,
  dynamicsUuid: string;
  number?: number;
  orderDate?: string;
}

/**
 * Retrieve available products for a customer based on the filter.
 * 
 * @todo Add logging for error messages.
 */
export async function GetProductsByCustomer( filter: CustomerProductFilter ): Promise<Product[]> {

  let products: Product[] = [];

  try {
    let response = await sendApiRequest( '/oms/customers/availableProducts', filter );

    for ( const product of response?.data?.customerProducts ) {

      let mappedProduct: Partial<Product> = {
        itemNumber: product.itemNumber,
        displayName: product.description,
        unitPrice: product.unitPrice,
        discountPercent: product.discountPercent,
        isAvailable: product.isAvailable,
        baseUnitOfMeasureCode: product.baseUnitOfMeasureCode,
        growOpsDescription: product.growOpsDescription,
        growOpsInternalUnitCount: product.growOpsInternalUnitCount,
        growOpsInternalUnitWeight: product.growOpsInternalUnitWeight,
        growOpsInternalUnitUom: product.growOpsInternalUnitUom
      };

      products.push( new Product( mappedProduct ) );
    }
  }
  catch( e ) {

  }

  return products;
}