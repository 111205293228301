
import { validationMixin } from "vuelidate";
import { Vue, Component } from "vue-property-decorator";
import upperFirst from "lodash/upperFirst";
import cloneDeep from "lodash/cloneDeep";
import isObject from "lodash/isObject";
// @ts-ignore
import { ref, withParams } from "vuelidate/lib/validators/common";

// Custom Validation Rules
export const noWhitespace = (value: string) => !value.match(/\s/);

// Custom Validation Error Messages
function maxValueMessage(label: string, params: any) {
  let max: string | number = "max value";
  if (params.hasOwnProperty("value")) max = params.value;
  else if (params.hasOwnProperty("max")) max = params.max;
  if (params.maxExclusive) {
    return `${label} must be less than ${max}`;
  }
  return `${label} must be less than or equal to ${max}`;
}
function minValueMessage(label: string, params: any) {
  let min: string | number = "min value";
  if (params.hasOwnProperty("value")) min = params.value;
  else if (params.hasOwnProperty("min")) min = params.min;
  if (params.minExclusive) {
    return `${label} must be greater than ${min}`;
  }
  return `${label} must be greater than or equal to ${min}`;
}
// Index signature is included for typescript
export const errorMessages: { [key: string]: any } = {
  required: (label: string) => `${label} is required`,
  numeric: (label: string) => `${label} must be numeric`,
  decimal: (label: string) => `${label} must be numeric`,
  noWhitespace: (label: string) => `${label} can't contain whitespace`,
  email: (label: string) => `${label} must be a valid email address`,
  url: (label: string) => `${label} must be a valid URL`,
  minLength: (label: string, params: any) =>
    `${label} must be greater than or equal to ${params.min} characters`,
  maxLength: (label: string, params: any) =>
    `${label} must be less than or equal to ${params.max} characters`,
  maxValue: (label: string, params: any) => maxValueMessage(label, params),
  minValue: (label: string, params: any) => minValueMessage(label, params),
  between: (label: string, params: any) =>
    `${label} must be between ${params.min} and ${params.max}`,
  sameAs: (label: string, params: any) => `${label} must match`
};

@Component({
  mixins: [validationMixin]
})
export default class Form extends Vue {
  // LOCAL VARIABLES -----------------------------------------------------------
  isDuplicate: boolean = false;
  isLoading: boolean = false;
  isValid: boolean = false;
  validationHasRun: boolean = false;
  formResponse: any = {
    show: false,
    msg: "",
    type: "info"
  };

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Returns an array of error messages based on validation rules
   *
   * @param {string} field The form field to validate
   * @param {string} label The user-friendly label for the field
   * @param {any} value The value that constrains the rule, ie computed max val
   * @returns {Array} Array of error messages to display
   */
  getErrors(field: string, label: string = "", value: any = "") {
    let errors: any = [];
    let validate: any = this.getValidationObject(field);
    // check if field exists in validation object
    if (validate) {
      // if field hasn't been touched, return empty errors array
      if (!validate.$dirty) return errors;
      for (let rule in validate.$params) {
        let fieldName = label.length > 0 ? label : upperFirst(field);
        let params: any = cloneDeep(validate.$params[rule]);
        if (value && isObject(value)) {
          for (let key in value) {
            // @ts-ignore
            params[key] = value[key];
          }
        } else if (value) params.value = value;
        // if a rule for this field doesn't pass validation,
        // add error message to errors array
        !validate[rule] && errors.push(errorMessages[rule](fieldName, params));
      }
    }
    return errors;
  }

  /**
   * Validates a single input, so errors can be displayed
   * per field as the form is being filled out
   *
   * @param {string} property The property to validate
   */
  validateInput(property: string = "") {
    let validate: any = this.getValidationObject(property);
    if (validate) {
      validate.$touch();
      if (validate.$invalid) {
        this.isValid = false;
      } else this.isValid = true;
    }
  }

  /**
   * Traverses the validation object for nested properties
   *
   * @param {string} property The property name to find
   * @returns {Object|null} The validation object
   */
  getValidationObject(property: string = "") {
    if (!this.$v) return null;
    let validate: any = this.$v;
    let properties: any = property.split(".");
    for (let p of properties) {
      validate = validate[p];
    }
    return validate || null;
  }
}
