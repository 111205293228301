
import Vue from 'vue';
import Component from 'vue-class-component';
import { DateTime } from 'luxon';
import { formatCurrency } from '@/util/helpers';

@Component
export default class SalesOrderConfirmationForm extends Vue {

  //----------------------------------------------------------------------------
  // Local Variables
  //----------------------------------------------------------------------------

  productHeaders = [
    { text: 'Item #', value: 'itemNumber' },
    { text: 'Description', value: 'displayName' },
    { text: 'Quantity', value: 'quantity', align: 'end' },
    { text: 'Unit Price', value: 'unitPrice', align: 'end' },
    { text: 'Discount %', value: 'discountPercent' },
    { text: 'Subtotal', value: 'netAmount', align: 'end' }
  ];


  //----------------------------------------------------------------------------
  // Computed Properties
  //----------------------------------------------------------------------------

  get customerName() {
    let customer = this.$store.getters['user/getCustomerByUuid']( this.$store.state.salesOrder.customerUuid );
    return customer?.name || '';
  }

  get requestedDeliveryDate() {
    return DateTime.fromISO( this.$store.state.salesOrder.requestedDeliveryDate )
      .toFormat( 'DDDD' );
  }

  get purchaseOrderNumber() {
    return this.$store.state.salesOrder.purchaseOrderNumber || ' ';
  }

  get products() {
    return [ ...this.$store.state.salesOrder.productCart.values() ];
  }

  get showRebrandedItemAlert(): boolean {

    const startDate = DateTime.fromISO( '2023-06-08' ),
          currentDate = DateTime.now(),
          isValidDate: boolean = ( startDate.startOf( 'day' ) <= currentDate && currentDate <= startDate.endOf( 'month' ) ),
          hasRebrandedItem: boolean = this.products.some( product => product.itemNumber === "10379" );

    return hasRebrandedItem && isValidDate;
  }

  get rebrandedItemNote(): string {

    const currentDate = DateTime.now(),
          startDate = DateTime.fromISO( '2023-06-13' );

    let note = 'SPRING BREAK &mdash; renamed FRESH SQUEEZE beginning June 14th';

    if ( startDate.startOf( 'day' ) <= currentDate ) {
      note = 'FRESH SQUEEZE &mdash; formerly SPRING BREAK';
    }

    return note;
  }

  //----------------------------------------------------------------------------
  // Lifecycle Events
  //----------------------------------------------------------------------------



  //----------------------------------------------------------------------------
  // Methods
  //----------------------------------------------------------------------------

  editOrder() {
    this.$emit( 'edit-order' );
  }

  submitOrder() {
    // Add a validation step to double-check if the delivery date is still valid
    // when they go to submit, because of the cut-off time.
    this.$emit( 'submit-order' );
  }

  formatCurrency( amount: number ) {
    return formatCurrency( amount );
  }
}
