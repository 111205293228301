import Product from '@/model/product';

interface SalesOrderState {
  customerUuid: string;
  requestedDeliveryDate: string;
  purchaseOrderNumber: string;
  availableProducts: Product[];
  productCart: Map<string, Product>;
}

const getDefaultState = () => {
  return {
    customerUuid: '',
    requestedDeliveryDate: '',
    purchaseOrderNumber: '',
    availableProducts: [],
    productCart: new Map(),
  } as SalesOrderState;
};


//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
const state = getDefaultState();


//----------------------------------------------------------------------------
// Getters
//----------------------------------------------------------------------------

const getters = {

  getAvailableProducts: ( state: SalesOrderState ): Product[] => {
    return state.availableProducts;
  },

  getProductCart: ( state: SalesOrderState ): Product[] => {
    return Object.values( state.productCart );
  }
};


//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------

const mutations = {

  reset( state: SalesOrderState ) {
    Object.assign( state, getDefaultState() );
  },

  setCustomerUuid( state: SalesOrderState, uuid: string ) {
    state.customerUuid = uuid;
  },

  setRequestedDeliveryDate( state: SalesOrderState, deliveryDate: string ) {
    state.requestedDeliveryDate = deliveryDate;
  },

  setPurchaseOrderNumber( state: SalesOrderState, purchaseOrderNumber: string ) {
    state.purchaseOrderNumber = purchaseOrderNumber;
  },

  setAvailableProducts( state: SalesOrderState, products: Product[] ) {
    state.availableProducts = products;
  },

  updateCartItem( state: SalesOrderState, product: Product ) {
    state.productCart.set( product.itemNumber, product );
  },

  removeCartItem( state: SalesOrderState, productNumber: string ) {
    state.productCart.delete( productNumber );
  },

  emptyCart( state: SalesOrderState ) {
    state.productCart = new Map();
  },
};


//----------------------------------------------------------------------------
// Actions
//----------------------------------------------------------------------------

const actions = {

  async updateSelectedCustomer( context: any, customerUuid: string ) {

    // Update the customer ID
    context.commit( 'setCustomerUuid', customerUuid );

    // Empty the available products.
    context.commit( 'setAvailableProducts', [] );

    // Empty the cart.
    context.commit( 'emptyCart' );
  }
};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}