import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "./auth-guard";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/sales'
  },
  {
    path: '/sales',
    name: 'salesOrder',
    component: () => import( '@/views/SalesOrder.vue' ),
    meta: { requiresAuth: true },
    beforeEnter: authGuard
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
