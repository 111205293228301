import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import filter from "lodash/filter";
import moment from "moment";
import Dinero from "dinero.js";
import Product from "@/model/product";

export async function waitForReadystate() {
  if (typeof document !== "undefined" && document.readyState !== "complete") {
    await new Promise(resolve => {
      const cb = () => {
        window.requestAnimationFrame(resolve);
        window.removeEventListener("load", cb);
      };
      window.addEventListener("load", cb);
    });
  }
}

export function toTitleCase(name: string | undefined) {
  if (name) return upperFirst(camelCase(name));
  return "";
}

export function isObject(value: any) {
  return typeof value === "object" && value !== null;
}

export function toOrdinal(number: number) {
  let lastDigit = number % 10;
  switch (lastDigit) {
  case 1:
    return `${number}st`;
  case 2:
    return `${number}nd`;
  case 3:
    return `${number}rd`;
  default:
    return `${number}th`;
  }
}

export function formatCurrency( amount: number ): string {
  return Dinero({
    amount: Number.parseInt( ( amount * 100 ).toFixed( 0 ) )
  }).toFormat( '$0,0.00' );
}

export function emailToName(email: string) {
  let splitEmail: any, name: string;
  splitEmail = email.split("@")[0].split(".");
  if (splitEmail.length === 1) {
    return toTitleCase(splitEmail[0]);
  }
  name = splitEmail[0].replace(/^\w/, (c: string) => c.toUpperCase()) + " ";
  name += splitEmail[1].replace(/^\w/, (c: string) => c.toUpperCase());
  return name;
}

export function timestampToDateTime(timestamp: string) {
  return moment(timestamp).format("ddd, MMMM Do YYYY [at] h:mm a");
}

export function timestampToDate(timestamp: string) {
  return moment(timestamp).format("ddd, MMMM Do YYYY");
}

export function timestampToSimpleDate(timestamp: string) {
  return moment(timestamp).format("MM/DD/YYYY");
}

export function timestampToSimpleDateYearless(timestamp: string) {
  return moment(timestamp).format("MM/DD");
}

export function timestampToDatePicker(timestamp: string) {
  return moment(timestamp).format("YYYY-MM-DD");
}

export function timestampToActivity(timestamp: string) {
  let out = moment(timestamp).fromNow();
  return out === "in a few seconds" ? "just now" : out;
}

export function formatTimestamp(timestamp: string, type: string = "datetime") {
  if (!moment(timestamp).isValid()) return timestamp;
  switch (type) {
  case "date": {
    return timestampToDate(timestamp);
  }
  case "activity": {
    return timestampToActivity(timestamp);
  }
  case "simple": {
    return timestampToSimpleDate(timestamp);
  }
  case "simple-yearless": {
    return timestampToSimpleDateYearless(timestamp);
  }
  case "datepicker": {
    return timestampToDatePicker(timestamp);
  }
  default: {
    return timestampToDateTime(timestamp);
  }
  }
}

export function getUTCNow() {
  return moment()
    .utc()
    .format();
}

export function getUTCFromDate(date: string = "") {
  if (!moment(date).isValid()) return date;
  return moment(date)
    .utc()
    .format();
}

export function getToday() {
  return getUTCNow();
}

export function getTomorrow() {
  return getDaysFromToday(1);
}

export function getDaysFromToday(days: number = 0) {
  return moment()
    .add(days, "days")
    .utc()
    .format();
}

export function timestampFromDate(date: string) {
  if (!moment(date).isValid()) return date;
  let timestamp: string = moment(date)
    .utc()
    .format();
}

export function filterItems(
  items: any = [],
  filters: any = [],
  search: string = ""
) {
  if (items.length === 0) return items;
  for (let f of filters) {
    // If the filter field is search, stringify the entire
    // item and filter by value
    if (f.field === "search") {
      items = filter(items, (s: any) => {
        return JSON.stringify(s)
          .toLowerCase()
          .includes(f.value);
      });
    }
    // Otherwise, try to match the filter field with a field
    // in the item. Then check against value
    else {
      items = filter(items, (s: any) => {
        if (s[f.field] && !f.exclude) {
          return JSON.stringify(s[f.field])
            .toLowerCase()
            .includes(f.value);
        }
        // Allows for exlusion of field, for example, excluding
        // order with complete stage
        else if (s[f.field] && f.exclude) {
          return !JSON.stringify(s[f.field])
            .toLowerCase()
            .includes(f.value);
        }
        return false;
      });
    }
  }
  // Check against temporary search filter
  if (search.length > 0) {
    items = filter(items, (s: any) => {
      return JSON.stringify(s)
        .toLowerCase()
        .includes(search);
    });
  }
  return items;
}

export function formatPhoneNumber(phone: string = "") {
  if (!phone) return "";
  if (phone.length !== 10) return phone;
  return `
    (${phone.substring(0, 3)}) ${phone.substring(3, 6)}
    -${phone.substring(6, 10)}
  `;
}

export function padNumber(number: string | number = 0, length: number = 0) {
  number = number || "";
  number = number.toString();
  if (number.length >= length) return number;
  while (number.length < length) number = "0" + number;
  return number;
}

export function padLotNumber(lot: string | number = "") {
  return padNumber(lot, 7);
}

export function formatDeliveryNumber(number: string | number = "") {
  return "DL/" + padNumber(number, 5);
}

export function formatSalesOrderNumber(number: string | number = "") {
  return "SO/" + padNumber(number, 5);
}

export function formatInvoiceNumber(number: string | number = "") {
  return "INV/" + padNumber(number, 5);
}

export function formatPurchaseOrderNumber(number: string | number = "") {
  return "PO/" + padNumber(number, 5);
}

export function formatPackoutOrderNumber(number: string | number = "") {
  return "PK/" + padNumber(number, 5);
}

export function round(value: number, decimals: number): number {
  // @ts-ignore JS understands "e".
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
    decimals
  );
}

export function findCustomerRef(details: any, customerId: string) {
  if (!details) return '';
  for (let ref of details.customer_refs) {
    if (ref.customer_id == customerId) {
      return ref.reference;
    }
  }
}

export function getImageSrcFromBase64(base64: string): string {
  if (!base64) return "";
  const fileTypeCode = base64.charAt(0);
  let extension = "";
  if (fileTypeCode === "/") extension = "jpg";
  if (fileTypeCode === "R") extension = "gif";
  if (fileTypeCode === "i") extension = "png";
  if (fileTypeCode === "U") extension = "webp";
  if (fileTypeCode === "P") extension = "svg";
  if (!extension) return "";

  return `data:image/${extension};base64,${base64}`;
}

//TODO: CHANGE REFERENCES TO 'new Product' function instead.
export function mapProduct(productRaw: any):Product {
  return new Product(productRaw);
}
