import { sendApiRequest } from "@/api/AxiosWrapper";
import { AppSettings } from "@eaua/model";

/**
 * Retrieve the customers that a user has access to.
 */
export async function GetAppSettingsByAppName( appName: string ) {
  let appSettings: any[] = [];

  let response = await sendApiRequest("/oms/settings", {appName})

  for ( const row of response.data.shared_app_settings ) {
    
    let appSetting: AppSettings["properties"] = {
      uuid: row.uuid,
      name: row.name,
      current_version: row.current_version,
      maintenance_mode: row.maintenance_mode,
      maintenance_message: row.maintenance_message
    }
    appSettings.push(appSetting);
  }

  const [ appSetting ] = appSettings;

  return appSetting;
}
